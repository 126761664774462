import axios from "axios";
import axiosRetry from "axios-retry";
import store from "../store";
import { getCsrfToken } from "/src/service/users.service.js";
// Set up axios-retry
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => axiosRetry.exponentialDelay(1, undefined, 1000),
  retryCondition: (error) => {
    if (error.config.method === "get" && error.response.status >= 500) {
      return true;
    }
    return false;
  },
});
// Normal process
const API_NOT_HANDLE_ERROR = [
  { url: "/api/v1/user_info", methods: ["get"] },
  { url: "/api/v1/property_blocks", methods: ["put"], status: 400 },
  { url: "/api/v1/tasks/aggregate", methods: ["post"], status: 400 },
  { url: "/api/v1/tasks/aggregate", methods: ["post"], status: 500 },
  { url: "/api/v1/csrf_token", methods: ["get"], status: 500 },
  { url: "/api/v1/new_mail_contents", methods: ["put"], status: 400 },
  {
    url: new RegExp("^/api/v1/salesforce_properties/[a-f0-9-]{36}$"),
    methods: ["get"],
    status: 404,
  },
];
const selfHandleErrorResponse = (error) => {
  // Check if error is handle by component or not
  let url = new URL(error.response.request.responseURL);
  let method = error.response.config.method;
  let status = error.response.status;
  let isHandle = false;
  API_NOT_HANDLE_ERROR.forEach((api) => {
    const isMatchURL =
      api.url instanceof RegExp
        ? api.url.test(url.pathname)
        : url.pathname === api.url;
    if (
      isMatchURL &&
      api.methods.includes(method) &&
      (!api.status || api.status === status)
    ) {
      isHandle = true;
    }
  });
  return isHandle;
};
const selfHandleRequest = (config) => {
  let url = config.url.split("/");
  return url.includes("aggregate") || url.includes("csrf_token");
};
axios.interceptors.request.use(
  async function (config) {
    // Check if user_object in localStorage
    if (localStorage.getItem("user_object") === null) {
      let path = window.location.pathname;
      window.location.href = "/" + `?redirect_url=${path}`;
      return;
    }
    if (["post", "put", "delete", "patch"].includes(config.method)) {
      config.headers["x-csrf-token"] = await preventCSRF();
    }
    if (!selfHandleRequest(config)) store.commit("loading/setLoading", true);
    config.headers["x-amzn-oidc-identity"] = store.getters["user/getHeaders"];
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  function (config) {
    setTimeout(() => {
      store.commit("loading/setLoading", false);
    }, 300);
    return config;
  },
  function (error) {
    setTimeout(() => {
      store.commit("loading/setLoading", false);
    }, 300);
    // Check if status >= 500
    if (error.response.status >= 500) {
      store.commit("confirmapi/setConfirm", {
        status: true,
        mes: `サーバにはエラーが発生しました（${error.response.status}）。`,
      });
      return Promise.reject(error);
    }
    // Check if user changed or deleted
    if (
      error.config.url.includes("/api/v1") &&
      (error.response.status === 403 || error.response.status === 401)
    ) {
      window.location.href = "/";
      return;
    }
    // Normal process
    // Check if error is handled by component
    if (selfHandleErrorResponse(error)) {
      throw error;
    }
    // Error is handld by general
    if (checkNested(error, "response", "data", "detail", "msg")) {
      store.commit("confirmapi/setConfirm", {
        status: true,
        mes: error.response.data.detail.msg,
      });
    } else {
      store.commit("confirmapi/setConfirm", {
        status: true,
        mes: checkMsg(error),
        // mes: error.message,
      });
    }
    return Promise.reject(error);
  }
);
function checkNested(obj, level, ...rest) {
  if (obj === undefined) return false;
  if (rest.length == 0 && level in obj) return true;
  return checkNested(obj[level], ...rest);
}
function checkMsg(error) {
  if (error.response.status == 422) {
    let floc = error.response.data.detail.find((item) => item.loc);
    if (floc.loc.includes("path") || floc.loc.includes("query"))
      return "Not Found";
  }
  return error.message;
}
async function preventCSRF() {
  // Get token from API
  return await getCsrfToken().then((response) => {
    return response.headers["x-csrf-token"];
  });
}
