<template>
  <BaseLayout>
    <div class="minh-520">
      <div class="wrap-ct border-0">
        <div class="custom-tab">
          <nav>
            <div class="tab-back">
              <button
                class="btn btn-cus btn-blue-light px-4"
                @click="$router.go(-1)">
                <span>戻 る</span>
              </button>
            </div>
            <div id="nav-tab" class="nav nav-tabs" role="tablist">
              <a
                id="nav-1-tab"
                class="nav-item nav-link active"
                data-toggle="tab"
                href="#nav-1"
                role="tab"
                aria-controls="nav-1"
                aria-selected="true"
                >メール作成</a
              >
              <a
                v-if="isActive"
                id="nav-2-tab"
                class="nav-item nav-link"
                data-toggle="tab"
                href="#nav-2"
                role="tab"
                aria-controls="nav-2"
                aria-selected="false"
                >送信顧客一覧</a
              >
            </div>
          </nav>
          <div id="nav-tabContent" class="tab-content">
            <MailCreation
              :has-customers="customers.length !== 0"
              @active-customers="activateCustomers"
              @inactive-customers="deactivateCustomers" />
            <SendingCustomers
              v-if="isActive"
              :total="total"
              :page="page"
              :size="size"
              :pages="pages"
              :current-order="order_by"
              :customers="customers"
              @change-page="fetchData"
              @sort-field="sortData" />
          </div>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import { getSendingCustomers } from "/src/service/customer-action-histories.service.js";

import BaseLayout from "/src/components/layouts/BaseLayout.vue";
import SendingCustomers from "/src/components/new-mails/sending-plan/SendingCustomers.vue";
import MailCreation from "/src/components/new-mails/sending-plan/MailCreation.vue";

export default {
  components: {
    BaseLayout,
    SendingCustomers,
    MailCreation,
  },
  data() {
    return {
      total: 0,
      page: 1,
      size: 50,
      pages: 1,
      order_by: "+customer_id",
      isActive: false,
      customers: [],
      propertyId: null,
      blockId: null,
    };
  },
  methods: {
    async activateCustomers(params) {
      this.isActive = true;
      this.propertyId = params.propertyId;
      this.blockId = params.blockId;
      if (this.customers.length === 0) await this.fetchData(1);
      this.$el.querySelector("#nav-2-tab").click();
    },
    deactivateCustomers() {
      this.isActive = false;
      this.customers = [];
    },
    async fetchData(page) {
      this.customers = [];
      const res = await getSendingCustomers({
        similar_property_id: this.propertyId,
        similar_block_id: this.blockId,
        page: page,
        size: this.size,
        order_by: this.order_by,
      });
      this.customers = res.data.items;
      this.page = res.data.page;
      this.total = res.data.total;
      this.pages = res.data.pages;
    },
    sortData(newOrder) {
      this.order_by = newOrder;
      this.fetchData(1);
    },
  },
};
</script>
