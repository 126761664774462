<template>
  <div
    id="nav-2"
    class="tab-pane fade"
    role="tabpanel"
    aria-labelledby="nav-1-tab">
    <div class="m-4">
      <div class="wapper-table">
        <div class="row align-items-center mx-2 py-4 mb-1">
          <div class="col-12 text-right">
            <label class="fs-18 mb-0">{{ total }}件</label>
          </div>
        </div>
        <div class="border">
          <ScrollTableLayout>
            <table class="table table-striped mb-0">
              <thead>
                <tr class="text-nowrap text-center">
                  <th class="w-215 minw-190">
                    <SortColumn
                      title="氏 名"
                      field="full_name_kanji"
                      :current-order="currentOrder"
                      @sort-field="sortField" />
                  </th>
                  <th class="w-215 minw-190">
                    <SortColumn
                      title="フリガナ"
                      field="full_name_furigana"
                      :current-order="currentOrder"
                      @sort-field="sortField" />
                  </th>
                  <th>
                    <SortColumn
                      title="メールアドレス"
                      field="email"
                      :current-order="currentOrder"
                      @sort-field="sortField" />
                  </th>
                  <th>
                    <SortColumn
                      title="住 所"
                      field="address_prefecture,address_district"
                      :current-order="currentOrder"
                      @sort-field="sortField" />
                  </th>
                  <th>
                    <SortColumn
                      title="希望問取リ"
                      field="demand_floor_plans1,demand_floor_plans2"
                      :current-order="currentOrder"
                      @sort-field="sortField" />
                  </th>
                  <th>
                    <SortColumn
                      title="予 算"
                      field="budget_limit"
                      :current-order="currentOrder"
                      @sort-field="sortField" />
                  </th>
                  <th>
                    <SortColumn
                      title="家族数"
                      field="family_size"
                      :current-order="currentOrder"
                      @sort-field="sortField" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in customers" :key="index">
                  <td>{{ row.full_name_kanji }}</td>
                  <td>{{ row.full_name_furigana }}</td>
                  <td class="text-nowrap">
                    <span class="txt-underline" style="color: #007bff">
                      {{ row.email }}
                    </span>
                  </td>
                  <td class="text-nowrap">{{ getAddress(row) }}</td>
                  <td class="text-right">
                    <span>{{ row.demand_floor_plans1_txt }}～</span>
                    <br
                      v-show="
                        row.demand_floor_plans1_txt &&
                        row.demand_floor_plans2_txt
                      " />
                    <span>{{ row.demand_floor_plans2_txt }}</span>
                  </td>
                  <td class="text-right">
                    {{ func.formatNumber(row.budget_limit) }}
                  </td>
                  <td class="text-right">{{ row.family_size }}</td>
                </tr>
              </tbody>
            </table>
          </ScrollTableLayout>
        </div>
        <BasicPaginate
          :page="page"
          :size="size"
          :pages="pages"
          :total="total"
          :click-handler="changePage" />
      </div>
    </div>
  </div>
</template>

<script>
import ScrollTableLayout from "/src/components/layouts/ScrollTableLayout.vue";
import BasicPaginate from "/src/components/commons/BasicPaginate.vue";
import SortColumn from "/src/components/utilitys/SortColumn.vue";

export default {
  components: {
    ScrollTableLayout,
    BasicPaginate,
    SortColumn,
  },
  props: {
    customers: { type: Array, default: () => [] },
    total: { type: Number, required: true },
    page: { type: Number, required: true },
    size: { type: Number, required: true },
    pages: { type: Number, required: true },
    currentOrder: { type: String, required: true },
  },
  emits: ["sort-field", "change-page"],
  data() {
    return {
      sortable: {
        // - is desc, + is asc
        full_name_kanji: null,
        full_name_furigana: null,
        email: null,
        address: null,
        floorPlans: null,
        budget_limit: null,
        family_size: null,
      },
    };
  },
  methods: {
    changePage(page) {
      this.$emit("change-page", page);
    },
    getAddress(item) {
      const prefecture = item.address_prefecture || "";
      const district = item.address_district || "";
      return `${prefecture}${district}`;
    },
    sortField(newOrder) {
      this.$emit("sort-field", newOrder);
    },
  },
};
</script>
