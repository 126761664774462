<template>
  <ScrollTableLayout>
    <table class="table table-striped table-border-vertical">
      <thead>
        <tr class="text-center">
          <th>
            <div class="sorted">
              ステータス
              <div class="sorting">
                <span
                  class="sorting_asc"
                  :class="{ active: arrowUp === buffer }"
                  @click="emitClick('up')"
                  ><i class="fas fa-caret-up"></i></span
                ><span
                  class="sorting_desc"
                  :class="{ active: arrowdown === buffer }"
                  @click="emitClick('down')"
                  ><i class="fas fa-caret-down"></i
                ></span>
              </div>
            </div>
          </th>
          <th>問合せ区分</th>
          <th class="w-300">反響先</th>
          <th class="w-215">氏 名</th>
          <th>都道府県</th>
          <th>市区町村</th>
          <th>メールアドレス</th>
          <th>電話番号</th>
          <th>第一希望日</th>
          <th>登録日時</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.id">
          <td class="text-center">{{ convertFollowStatus(item.follow_status) }}</td>
          <td class="text-center">
            <router-link
              :to="{
                name: 'inquiry-reservation-id',
                params: { inquiry_id: item.inquiry_id },
              }"
              class="txt-underline"
              >{{ convertNumToText(item.inquiry_classification) }}
            </router-link>
          </td>
          <td>{{ item ? item.property_name : "" }}</td>
          <td>{{ item.customer ? item.customer.full_name_kanji : "" }}</td>
          <td>{{ item.customer ? item.customer.address_prefecture : "" }}</td>
          <td>{{ item.customer ? item.customer.address_district : "" }}</td>
          <td class="text-nowrap">
            {{ item.customer ? item.customer.email : "" }}
          </td>
          <td class="text-nowrap">{{ item.telephone_number }}</td>
          <td class="text-nowrap text-right">
            {{ func.formatDate(item.preferred_date_1, { dateStyle: "long" }) }}
          </td>
          <td class="text-nowrap text-right">
            {{
              item.created_at
                ? func.formatDate(item.created_at, {
                    dateStyle: "long",
                    timeStyle: "short",
                  })
                : ""
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </ScrollTableLayout>
</template>

<script>
import {
  inquiryClassification,
  inquiryStatus,
} from "/src/components/inquiry/const.js";
import ScrollTableLayout from "/src/components/layouts/ScrollTableLayout.vue";
export default {
  components: {
    ScrollTableLayout,
  },
  props: ["items"],
  data() {
    return {
      arrowUp: false,
      arrowdown: true,
      buffer: null,
      inquiryClassification,
      inquiryStatus,
    };
  },
  methods: {
    convertNumToText(num) {
      if (num == null) {
        return "";
      }
      if (num == 1) {
        return inquiryClassification[0].label;
      } else {
        return inquiryClassification[1].label;
      }
    },
    convertFollowStatus(num) {
      if (num == null) {
        return "";
      }
      if (num == 0) {
        return inquiryStatus[1].label;
      } else {
        return inquiryStatus[0].label;
      }
    },
    emitClick(type) {
      if (type == "up") {
        if (this.arrowUp != this.buffer) {
          this.buffer = this.arrowUp;
          this.$emit("sort", false);
        }
      } else if (type == "down") {
        if (this.arrowdown != this.buffer) {
          this.buffer = this.arrowdown;
          this.$emit("sort", true);
        }
      }
    },
  },
};
</script>
