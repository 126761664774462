<template>
  <div class="sorted">
    {{ title }}
    <div class="sorting">
      <span
        class="sorting_asc"
        :class="{ active: currentOrder == genOrder(false) }"
        @click="sortField(false)">
        <i class="fas fa-caret-up"></i>
      </span>
      <span
        class="sorting_desc"
        :class="{ active: currentOrder == genOrder(true) }"
        @click="sortField(true)">
        <i class="fas fa-caret-down"></i>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    currentOrder: { type: String, required: true },
    field: { type: String, required: true },
  },
  emits: ["sort-field"],
  methods: {
    genOrder(desc) {
      const sign = desc ? "-" : "+";
      const orderBy = this.field
        .split(",")
        .map((el) => `${sign}${el}`)
        .join(",");
      return orderBy;
    },
    sortField(desc) {
      this.$emit("sort-field", this.genOrder(desc));
    },
  },
};
</script>
