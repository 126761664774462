<template>
  <OldInHouse
    v-if="!isLoading"
    :data="showData"
    :text-button="textButton"
    :schedule="schedule"
    :building-number="buildingNumber" />
</template>

<script>
import { getUuid } from "/src/components/recommend-properties/commons/utils/format.js";
import { getSalesForceDetail } from "/src/service/salesforce.js";
import OldInHouse from "/src/components/recommend-properties/OldInHouse.vue";
import {
  getPreviousDay,
  returnTextButton,
} from "/src/components/recommend-properties/commons/utils/format.js";
import { getRecommendScheduleCalendars } from "/src/service/recommend-schedule-calendar.js";
import { showData } from "/src/helpers/info-properties.js";
import {
  getOldInHouseHeader,
  getOldInHouseImage,
  getOldInHouseIcon,
  getOldInHouseTable,
} from "/src/components/recommend-properties/commons/utils/oldInHouse.js";
import { mapState } from "vuex";

export default {
  components: { OldInHouse },
  data() {
    return {
      getUuid,
      showData,
      data: { salesforce_property: {} },
      textButton: "",
      buildingNumber: "",
      schedule: {
        release_date: "",
        expiry_date: "",
        expiry_date_before_one_day: "",
      },
    };
  },
  computed: {
    ...mapState("loading", ["isLoading"]),
  },
  created() {
    const uuid = this.getUuid();
    getSalesForceDetail(uuid)
      .then((data) => {
        this.buildingNumber = data.data.building_number;
        this.textButton = returnTextButton(
          data.data.block_classification,
          data.data.completion_year_and_month
        );
        this.data.salesforce_property = data.data;
        getOldInHouseHeader(this.data, this.showData);
        getOldInHouseImage(this.data, this.showData);
        getOldInHouseIcon(this.data, this.showData);
        getOldInHouseTable(this.data, this.showData);
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          const url = this.$router.resolve({ name: "error404" }).href;
          window.location.href = url;
        }
      });
    getRecommendScheduleCalendars().then((data) => {
      this.schedule.release_date = data.data.release_date;
      this.schedule.expiry_date = data.data.expiry_date;
      this.schedule.expiry_date_before_one_day = getPreviousDay(
        data.data.expiry_date
      );
    });
  },
};
</script>
