<template>
  <BaseLayout>
    <div class="minh-520">
      <div class="wrap-ct border-0">
        <div class="custom-tab">
          <nav>
            <div class="tab-back">
              <button
                class="btn btn-cus btn-blue-light px-4"
                @click="navigateBack()">
                <span>戻 る</span>
              </button>
            </div>
            <div id="nav-tab" class="nav nav-tabs" role="tablist">
              <a
                id="nav-1-tab"
                class="nav-item nav-link active"
                data-toggle="tab"
                href="#nav-1"
                role="tab"
                aria-controls="nav-1"
                aria-selected="true"
                >メール内容</a
              >
              <a
                id="nav-2-tab"
                class="nav-item nav-link"
                data-toggle="tab"
                href="#nav-2"
                role="tab"
                aria-controls="nav-2"
                aria-selected="false"
                @click="getNewMailInterface()"
                >送信顧客一覧</a
              >
            </div>
          </nav>
          <div id="nav-tabContent" class="tab-content">
            <div
              id="nav-1"
              class="tab-pane fade show active"
              role="tabpanel"
              aria-labelledby="nav-3-tab">
              <div class="mx-4">
                <div class="cus-filter my-0 border-0">
                  <div class="card-body px-0 custom-form">
                    <div class="row align-items-center">
                      <div class="col-12 col-lg-3 col-xl-2 my-2">
                        <label class="mb-0">物 件</label>
                      </div>
                      <div class="col-12 col-lg-8 col-xl-2 my-2">
                        <div class="custom-select2">
                          <select
                            class="form-control distribution-select1"
                            disabled>
                            <!-- Property name -->
                            <option value="1">
                              {{ newMailContent.property?.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-12 col-lg-3 col-xl-1 my-2 text-xl-center">
                        <label class="text-nowrap mb-0">表示区画</label>
                      </div>
                      <div class="col-12 col-lg-8 col-xl-2 my-2 mr-sm-3">
                        <div class="custom-select2">
                          <select
                            class="form-control distribution-select1"
                            disabled>
                            <!-- Block name -->
                            <option value="1">
                              {{
                                newMailContent.property_block?.block_name
                              }}&nbsp;({{
                                newMailContent.property_block.layout_type_txt
                              }})
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-12 col-lg-3 col-xl-1 my-2">
                        <label class="text-nowrap mb-0">送信日</label>
                      </div>
                      <div class="col-12 col-lg-8 col-xl-2 my-2">
                        <!-- Send date -->
                        <p class="text-nowrap mb-0">
                          {{
                            func.formatDate(newMailContent.plan_send_date, {
                              dateStyle: "long",
                            })
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-lg-3 col-xl-2 my-2">
                        <label>フリーテキスト</label>
                      </div>
                      <div class="col-lg-8 my-2">
                        <!-- free words -->
                        <textarea
                          id=""
                          v-model="newMailContent.free_words"
                          type="text"
                          name=""
                          class="form-control"
                          rows="6"
                          placeholder=""
                          disabled>
                        </textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="nav-2"
              class="tab-pane fade"
              role="tabpanel"
              aria-labelledby="nav-1-tab">
              <div class="mx-4 my-3">
                <div class="wapper-table">
                  <div class="row align-items-center mx-2 py-4 mb-1">
                    <div class="col-12 text-right">
                      <label class="fs-18 mb-0">{{ total }}件</label>
                      <label class="my-0 winw-100 mr-2 text-nowrap">
                        メール開封率</label
                      >
                      <input
                        :value="((opened_count / total) * 100).toFixed(1)"
                        type="text"
                        class="form-control w-84 d-inline-block"
                        disabled />
                      <label class="my-0 ml-1 text-nowrap">%</label>
                    </div>
                  </div>
                  <div class="border">
                    <ScrollTableLayout>
                      <table class="table table-striped mb-0">
                        <thead>
                          <tr class="text-nowrap text-center">
                            <th class="w-215 minw-190">
                              <SortColumn
                                title="氏 名"
                                field="customers.full_name_kanji"
                                :current-order="order_by"
                                @sort-field="sortField" />
                            </th>
                            <th class="w-215 minw-190">
                              <SortColumn
                                title="フリガナ"
                                field="customers.full_name_furigana"
                                :current-order="order_by"
                                @sort-field="sortField" />
                            </th>
                            <th>
                              <SortColumn
                                title="メールアドレス"
                                field="customers.email"
                                :current-order="order_by"
                                @sort-field="sortField" />
                            </th>
                            <th>
                              <SortColumn
                                title="住 所"
                                field="customers.address_prefecture,customers.address_district"
                                :current-order="order_by"
                                @sort-field="sortField" />
                            </th>
                            <th>
                              <SortColumn
                                title="希望問取リ"
                                field="customers.demand_floor_plans1,customers.demand_floor_plans2"
                                :current-order="order_by"
                                @sort-field="sortField" />
                            </th>
                            <th>
                              <SortColumn
                                title="予 算"
                                field="customers.budget_limit"
                                :current-order="order_by"
                                @sort-field="sortField" />
                            </th>
                            <th>
                              <SortColumn
                                title="家族数"
                                field="customers.family_size"
                                :current-order="order_by"
                                @sort-field="sortField" />
                            </th>
                            <th>
                              <SortColumn
                                title="開封日"
                                field="opened_at"
                                :current-order="order_by"
                                @sort-field="sortField" />
                            </th>
                            <th>
                              <SortColumn
                                title="PV回数"
                                field="new_mail_properties.number_of_page_views"
                                :current-order="order_by"
                                @sort-field="sortField" />
                            </th>
                            <th>
                              <SortColumn
                                title="担 当"
                                field="users.name"
                                :current-order="order_by"
                                @sort-field="sortField" />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(mail, index) in newMailList" :key="index">
                            <td>
                              {{ mail.customer?.full_name_kanji }}
                            </td>
                            <td>
                              {{ mail.customer?.full_name_furigana }}
                            </td>
                            <td class="text-nowrap">
                              <a class="txt-underline">{{
                                mail.customer?.email
                              }}</a>
                            </td>
                            <td>
                              {{ mail.customer?.address_prefecture
                              }}{{ mail.customer?.address_district }}
                            </td>
                            <td class="text-right">
                              {{
                                func.convertEnum(
                                  mail.customer?.demand_floor_plans1,
                                  BLOCK_LAYOUT_TYPE
                                )
                              }}{{
                                mail.customer
                                  ? mail.customer.demand_floor_plans1 ||
                                    mail.customer.demand_floor_plans2
                                    ? "～"
                                    : ""
                                  : ""
                              }}<br />{{
                                func.convertEnum(
                                  mail.customer?.demand_floor_plans2,
                                  BLOCK_LAYOUT_TYPE
                                )
                              }}
                            </td>
                            <td class="text-right">
                              {{ func.formatNumber(mail.customer?.budget_limit) }}
                            </td>
                            <td class="text-right">
                              {{ func.formatNumber(mail.customer?.family_size) }}
                            </td>
                            <td v-if="mail.opened_at" class="text-nowrap text-right">
                              {{
                                func.formatDate(mail.opened_at, {
                                  dateStyle: "long",
                                })
                              }}<br />{{
                                func.formatDate(mail.opened_at, {
                                  timeStyle: "short",
                                })
                              }}
                            </td>
                            <td v-else></td>
                            <td class="text-right">
                              {{
                                func.formatNumber(
                                  mail.new_mail_property?.number_of_page_views
                                )
                              }}
                            </td>
                            <td>
                              {{
                                func.displayValue(
                                  mail.new_mail_follow_priority_customers[0]
                                    ?.assigned_person?.name
                                )
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </ScrollTableLayout>
                  </div>
                  <BasicPaginate
                    :page="page"
                    :size="size"
                    :pages="pages"
                    :total="total"
                    :click-handler="fetchNewMail" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from "/src/components/layouts/BaseLayout.vue";
import ScrollTableLayout from "/src/components/layouts/ScrollTableLayout.vue";
import BasicPaginate from "/src/components/commons/BasicPaginate.vue";
import SortColumn from "/src/components/utilitys/SortColumn.vue";

import { BLOCK_LAYOUT_TYPE } from "/src/constants";
import {
  getNewMails,
  getNewMailContent,
} from "/src/service/new-mail.service.js";

export default {
  name: "SendMailConfirm",
  components: {
    BaseLayout,
    ScrollTableLayout,
    BasicPaginate,
    SortColumn,
  },
  data() {
    return {
      total: 0,
      page: 1,
      pages: 1,
      size: 50,
      order_by: "+customers.customer_id",
      params: {
        property_id: this.$route.params.property_id,
        plan_send_date: this.$route.params.plan_send_date,
      },
      newMailContent: {
        new_mail_content_ld: "",
        plan_send_date: "",
        free_words: "",
        property: {
          property_id: "",
          name: "",
        },
        property_block: {
          property_block_id: "",
          name: "",
          layout_type_txt: "",
        },
      },
      newMailList: [],
      opened_count: 0,
      BLOCK_LAYOUT_TYPE,
      arrowUp: "",
      arrowDown: "",
      sortable: {
        floorPlans: null, // - is desc, + is asc
        address: null,
      },
    };
  },
  created() {
    // get params from router
    getNewMailContent(this.params).then((res) => {
      this.newMailContent = res.data;
    });
  },
  methods: {
    fetchNewMail(page) {
      getNewMails({
        is_sent: true,
        property_id: this.params["property_id"],
        plan_send_from: this.params["plan_send_date"],
        plan_send_to: this.params["plan_send_date"],
        page: page,
        size: this.size,
        order_by: `${this.order_by},new_mail_id`,
      }).then((res) => {
        this.newMailList = res.data.items;
        this.page = res.data.page;
        this.total = res.data.total;
        this.pages = res.data.pages;
        this.opened_count = res.data.opened_count;
      });
    },
    getNewMailInterface() {
      if (!this.newMailList.length) this.fetchNewMail(1);
    },
    navigateBack() {
      this.func.navigateBack(this.$router, "new-mails-main");
    },
    sortField(newOrder) {
      this.order_by = newOrder;
      this.fetchNewMail(1);
    },
  },
};
</script>
